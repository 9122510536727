import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import {
  CommonControllerService,
  DeveloperControllerService,
  RankingControllerService,
  RankingQuery,
  RankingTypeMap,
  WorkspaceDto,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { RankingTable } from '../../components/ranking/RankingTable'
import { useNavigate } from 'react-router-dom'

interface Props {
  developerMode?: boolean
}

export const Ranking: FC<Props> = ({ developerMode = false }) => {
  const navigate = useNavigate()
  const [workspaces, setWorkspaces] = useState([] as WorkspaceDto[])
  const [workspaceId, setWorkspaceId] = useState<string>()

  const [rankingTypeMaps, setRankingTypeMaps] = useState([] as RankingTypeMap[])
  const [rankingType, setRankingType] = useState('')
  const [rankingQueries, setRankingQueries] = useState([] as RankingQuery[])
  const [filterDate, setFilterDate] = useState('')
  const [headContents, setHeadContents] = useState([
    '順位',
    'キューブ名',
    'キューブ数',
  ])

  useEffect(() => {
    // ランキング種別を取得
    CommonControllerService.getRankingTypes()
      .then(res => {
        if (rankingTypeMaps.length === 0) {
          setRankingTypeMaps(res)
          setRankingType(res[0].name)
        }

        // CUBEが含まれる場合は順位、キューブ名、キューブ数
        if ((rankingType || res[0].name).includes('CUBE')) {
          setHeadContents(['順位', 'キューブ名', 'キューブ数'])
        } else {
          setHeadContents(['順位', 'メンバー名', 'キューブ数'])
        }

        if (!rankingType) {
          return
        }

        const getRanking = developerMode
          ? DeveloperControllerService.getDeveloperRanking(
              // @ts-ignore
              rankingType,
              workspaceId,
            )
          : // @ts-ignore
            RankingControllerService.getRanking(rankingType)
        !(developerMode && !workspaceId) &&
          getRanking
            .then(res => {
              setRankingQueries(res)
              setFilterDate('')
            })
            .catch(apiErrorHandler)
      })
      .catch(apiErrorHandler)

    if (developerMode) {
      DeveloperControllerService.getAllWorkspaces()
        .then(setWorkspaces)
        .catch(apiErrorHandler)
    }
  }, [workspaceId, rankingType])

  const handleRankingType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRankingType(e.target.value)
    setFilterDate('')
    navigate('?page=1') // ページ番号をリセット
  }

  const handleFilterDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterDate(e.target.value)
    navigate('?page=1') // ページ番号をリセット
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="w-[calc(100%-223px)] ml-auto mt-[4.5rem] max-md:w-full max-md:mt-[3.25rem]">
        <div className="mx-auto max-w-[112.5rem] px-10 max-md:mb-[3.4375rem] max-md:px-0">
          <div className="mt-6 max-w-[101.0625rem] max-md:mt-2 max-md:p-4">
            {developerMode && workspaces.length !== 0 && (
              <div className="p-6 rounded-lg shadow-lg bg-white max-md:w-[640px] max-md:max-w-full max-md:mx-auto max-md:p-4 max-md:rounded-none max-md:shadow-none">
                <div className="w-full mt-4">
                  <div>
                    <label
                      htmlFor="workspace"
                      className="font-bold text-sm block text-left mb-3"
                    >
                      ワークスペース
                    </label>
                    <div className="relative">
                      <select
                        className="w-full text-left bg-gray-100 inline-block px-4 py-2.5 rounded-xl font-normal text-base cursor-pointer pr-10"
                        value={workspaceId}
                        onChange={e => setWorkspaceId(e.target.value)}
                      >
                        <option value="">選択してください</option>
                        {workspaces.map((workspace, index) => (
                          <option key={index} value={workspace.workspaceId}>
                            {workspace.workspaceName}
                          </option>
                        ))}
                      </select>
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 21"
                        fill="none"
                        aria-hidden="true"
                      >
                        <path
                          d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                          fill="#8A8F9F"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-6 max-w-[101.0625rem] max-md:mt-[0.6875rem] max-md:p-4">
            <div className="p-6 rounded-lg shadow-lg bg-white max-md:w-[640px] max-md:max-w-full max-md:mx-auto max-md:p-4 max-md:rounded-none max-md:shadow-none">
              <h3 className="font-bold text-lg justify-between block md:flex">
                <div className="relative w-full font-bold text-sm mb-4 md:w-80">
                  <span className="absolute top-1/2 left-0 pointer-events-none transform -translate-y-1/2">
                    <svg
                      className="w-full h-full"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Icon/Sort">
                        <path
                          id="Icon"
                          d="M6.66667 15H3.33333C3.09722 15 2.89944 14.92 2.74 14.76C2.58 14.6006 2.5 14.4028 2.5 14.1667C2.5 13.9306 2.58 13.7328 2.74 13.5733C2.89944 13.4133 3.09722 13.3333 3.33333 13.3333H6.66667C6.90278 13.3333 7.10083 13.4133 7.26083 13.5733C7.42028 13.7328 7.5 13.9306 7.5 14.1667C7.5 14.4028 7.42028 14.6006 7.26083 14.76C7.10083 14.92 6.90278 15 6.66667 15ZM16.6667 6.66667H3.33333C3.09722 6.66667 2.89944 6.58694 2.74 6.4275C2.58 6.2675 2.5 6.06944 2.5 5.83333C2.5 5.59722 2.58 5.39917 2.74 5.23917C2.89944 5.07972 3.09722 5 3.33333 5H16.6667C16.9028 5 17.1006 5.07972 17.26 5.23917C17.42 5.39917 17.5 5.59722 17.5 5.83333C17.5 6.06944 17.42 6.2675 17.26 6.4275C17.1006 6.58694 16.9028 6.66667 16.6667 6.66667ZM11.6667 10.8333H3.33333C3.09722 10.8333 2.89944 10.7533 2.74 10.5933C2.58 10.4339 2.5 10.2361 2.5 10C2.5 9.76389 2.58 9.56583 2.74 9.40583C2.89944 9.24639 3.09722 9.16667 3.33333 9.16667H11.6667C11.9028 9.16667 12.1008 9.24639 12.2608 9.40583C12.4203 9.56583 12.5 9.76389 12.5 10C12.5 10.2361 12.4203 10.4339 12.2608 10.5933C12.1008 10.7533 11.9028 10.8333 11.6667 10.8333Z"
                          fill="#8a8f9f"
                        />
                      </g>
                    </svg>
                  </span>
                  <select
                    className="pl-8 cursor-pointer"
                    onChange={handleRankingType}
                  >
                    {rankingTypeMaps.map((rankingTypeMap, index) => (
                      <option value={rankingTypeMap.name} key={index}>
                        {rankingTypeMap.label}
                      </option>
                    ))}
                  </select>
                  <div className="absolute top-1/2 right-0 bg-white transform -translate-y-1/2 pointer-events-none">
                    <svg
                      className="absolute top-1/2 right-0 transform -translate-y-1/2 pointer-events-none"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                        fill="#8A8F9F"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative pl-8 font-bold text-sm mb-4 w-full md:w-80">
                  <span className="absolute top-1/2 left-0 pointer-events-none transform -translate-y-1/2">
                    <svg
                      className="w-full h-full"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Icon/Sort">
                        <path
                          id="Icon"
                          d="M6.66667 15H3.33333C3.09722 15 2.89944 14.92 2.74 14.76C2.58 14.6006 2.5 14.4028 2.5 14.1667C2.5 13.9306 2.58 13.7328 2.74 13.5733C2.89944 13.4133 3.09722 13.3333 3.33333 13.3333H6.66667C6.90278 13.3333 7.10083 13.4133 7.26083 13.5733C7.42028 13.7328 7.5 13.9306 7.5 14.1667C7.5 14.4028 7.42028 14.6006 7.26083 14.76C7.10083 14.92 6.90278 15 6.66667 15ZM16.6667 6.66667H3.33333C3.09722 6.66667 2.89944 6.58694 2.74 6.4275C2.58 6.2675 2.5 6.06944 2.5 5.83333C2.5 5.59722 2.58 5.39917 2.74 5.23917C2.89944 5.07972 3.09722 5 3.33333 5H16.6667C16.9028 5 17.1006 5.07972 17.26 5.23917C17.42 5.39917 17.5 5.59722 17.5 5.83333C17.5 6.06944 17.42 6.2675 17.26 6.4275C17.1006 6.58694 16.9028 6.66667 16.6667 6.66667ZM11.6667 10.8333H3.33333C3.09722 10.8333 2.89944 10.7533 2.74 10.5933C2.58 10.4339 2.5 10.2361 2.5 10C2.5 9.76389 2.58 9.56583 2.74 9.40583C2.89944 9.24639 3.09722 9.16667 3.33333 9.16667H11.6667C11.9028 9.16667 12.1008 9.24639 12.2608 9.40583C12.4203 9.56583 12.5 9.76389 12.5 10C12.5 10.2361 12.4203 10.4339 12.2608 10.5933C12.1008 10.7533 11.9028 10.8333 11.6667 10.8333Z"
                          fill="#8a8f9f"
                        />
                      </g>
                    </svg>
                  </span>
                  <select
                    className="cursor-pointer"
                    onChange={handleFilterDateChange}
                    value={filterDate}
                  >
                    <option value="">全期間</option>
                    {rankingQueries
                      .map(rankingQuery => {
                        const date = new Date(rankingQuery.createdAt)
                        const year = date.getFullYear()
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          '0',
                        )
                        return { year, month }
                      })
                      .filter(
                        (date, index, self) =>
                          self.findIndex(
                            d => d.year === date.year && d.month === date.month,
                          ) === index,
                      )
                      .sort((a, b) => {
                        if (a.year < b.year) return 1
                        if (a.year > b.year) return -1
                        if (a.month < b.month) return 1
                        if (a.month > b.month) return -1
                        return 0
                      })
                      .map((date, index) => (
                        <option
                          value={`${date.year}-${date.month}`}
                          key={index}
                        >
                          {`${date.year}年${date.month}月`}
                        </option>
                      ))}
                  </select>
                  <div className="absolute top-1/2 right-0 bg-white transform -translate-y-1/2 pointer-events-none">
                    <svg
                      className="absolute top-1/2 right-0 transform -translate-y-1/2 pointer-events-none"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                        fill="#8A8F9F"
                      />
                    </svg>
                  </div>
                </div>
              </h3>
              <RankingTable
                developerMode={developerMode}
                workspaceName={
                  workspaces.find(
                    workspace => workspace.workspaceId === workspaceId,
                  )?.workspaceName
                }
                rankingTypeMaps={rankingTypeMaps}
                rankingType={rankingType}
                headContents={headContents}
                rankingQueries={rankingQueries.filter(rankingQuery => {
                  const date = new Date(rankingQuery.createdAt)
                  const year = date.getFullYear()
                  const month = String(date.getMonth() + 1).padStart(2, '0')
                  return filterDate === '' || `${year}-${month}` === filterDate
                })}
                filterDate={filterDate}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
