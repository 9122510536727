import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useModal } from '../../contexts/modalContext'
import { CubeDetailModal } from '../../modals/cubeDetailModal'
import { exportCsv } from '../../lib/common'
import { Button } from '../button'
import { Pagination } from '../pagination'
import { RankingQuery, RankingTypeMap } from '../../services'

interface TableProps {
  developerMode?: boolean
  workspaceName?: string
  rankingTypeMaps?: RankingTypeMap[]
  rankingType: string
  headContents: string[]
  rankingQueries: RankingQuery[]
  filterDate: string
}

interface RankingCount {
  id: number
  name: string
  count: number
}

export const RankingTable: FC<TableProps> = ({
  developerMode,
  workspaceName,
  rankingTypeMaps,
  rankingType,
  headContents,
  rankingQueries,
  filterDate,
}) => {
  const query = new URLSearchParams(useLocation().search)
  const page = parseInt(query.get('page') as string) || 1
  const limit = 30
  const navigate = useNavigate()
  const { openModal } = useModal()

  const [rankingCount, setRankingCount] = useState([] as RankingCount[])

  useEffect(() => {
    if (rankingQueries.length > 0) {
      const rankingCountMap = rankingQueries.reduce(
        (acc, rankingQuery) => ({
          ...acc,
          [rankingQuery.id]: (acc[rankingQuery.id] || 0) + 1,
        }),
        {} as { [key: string]: number },
      )

      const newRankingCount = rankingQueries
        .map(
          rankingQuery =>
            ({
              id: rankingQuery.id,
              name: rankingQuery.name,
              count: rankingCountMap[rankingQuery.id],
            } as RankingCount),
        )
        // 重複削除
        .filter(
          (rankingCount, index, self) =>
            self.findIndex(rc => rc.id === rankingCount.id) === index,
        )
        // ソート
        .sort((a, b) => b.count - a.count)

      setRankingCount(newRankingCount)
    }
  }, [rankingQueries])

  return rankingQueries.length === 0 ? (
    <p className="text-center p-4 text-gray-500 font-bold">
      データが存在しません
    </p>
  ) : (
    <>
      {developerMode && workspaceName && rankingTypeMaps && (
        <div className="flex justify-end mb-4">
          <Button
            className="block px-3 py-2.5 max-w-[72px] rounded-lg text-white border border-green bg-green text-xs font-bold transition-all duration-300 ease-out"
            onClick={() => {
              exportCsv(
                workspaceName +
                  '_' +
                  rankingTypeMaps.find(
                    rankingTypeMap => rankingTypeMap.name === rankingType,
                  )?.label +
                  '_' +
                  (filterDate ? filterDate : '全期間'),
                headContents,
                rankingCount.map((rankingCount, index) => [
                  (index + 1).toString(),
                  rankingCount.name,
                  rankingCount.count.toString(),
                ]),
              )
            }}
          >
            出力
          </Button>
        </div>
      )}
      <table className="table-fixed w-full border-separate border-spacing-0.5 text-center">
        <thead>
          <tr>
            {headContents.map((headContent, index) => (
              <th
                key={index}
                className={`${index === 0 ? 'rounded-tl-md' : ''} ${
                  index === 2 ? 'rounded-tr-md' : ''
                } ${
                  index === 0 ? 'w-[18px]' : 'w-[100px]'
                } text-white font-bold bg-green text-center text-sm`}
              >
                {headContent}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rankingCount
            .slice((page - 1) * limit, page * limit)
            .map((rankingCount, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                onClick={() => {
                  if (rankingType.includes('CUBE')) {
                    openModal(CubeDetailModal, {
                      cubeId: rankingCount.id,
                      yearMonth: filterDate,
                    })
                  } else {
                    // 別タブでメンバー詳細画面を開く
                    window.open(`/member-detail/${rankingCount.id}`, '_blank')
                  }
                }}
              >
                <td className="break-words">
                  {index + 1 + (page - 1) * limit}
                </td>
                <td className="break-words">{rankingCount.name}</td>
                <td className="break-words">{rankingCount.count}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        totalPage={Math.ceil(rankingCount.length / limit)}
        currentPage={page}
        goNext={() => navigate(`?page=${page + 1}`)}
        goBack={() => navigate(`?page=${page - 1}`)}
        goPage={(page: number) => navigate(`?page=${page}`)}
      />
    </>
  )
}
