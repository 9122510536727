import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Button } from '../button'

interface PaginationProps {
  currentPage: number
  totalPage: number
  goNext: () => void
  goBack: () => void
  goPage: (page: number) => void
}

export const Pagination: FC<PaginationProps> = ({
  currentPage = 1,
  totalPage = 1,
  goNext,
  goBack,
  goPage,
}) => {
  const [width, setWidth] = useState(window.innerWidth)
  const mobileWidth = 768

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const createBackButton = () => {
    const text = '前へ'
    if (currentPage === 1) {
      return (
        <Button className="w-20 px-3 py-3 text-xs flex justify-center items-center bg-gray-300 btn-primary-base md:w-32 md:px-5 md:text-sm">
          {text}
        </Button>
      )
    } else {
      return (
        <Button
          className="w-20 px-3 py-3 text-xs flex justify-center items-center btn-primary-base md:w-32 md:px-5 md:text-sm"
          onClick={goBack}
        >
          {text}
        </Button>
      )
    }
  }
  const createNextButton = () => {
    const text = '次へ'
    if (currentPage === totalPage) {
      return (
        <Button className="w-20 px-3 py-3 text-xs flex justify-center items-center bg-gray-300 btn-primary-base md:w-32 md:px-5 md:text-sm">
          {text}
        </Button>
      )
    } else {
      return (
        <Button
          className="w-20 px-3 py-3 text-xs flex justify-center items-center btn-primary-base md:w-32 md:px-5 md:text-sm"
          onClick={goNext}
        >
          {text}
        </Button>
      )
    }
  }

  const getPageNumbers = (currentPage: number, totalPage: number) => {
    // 表示するページ数（横幅によって調整）
    const visiblePages = width < mobileWidth ? 3 : 5
    const pageOffset = Math.floor(visiblePages / 2)

    const startPage = Math.max(currentPage - pageOffset, 1)
    const endPage = Math.min(currentPage + pageOffset, totalPage)

    const pages = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    // 最初のページと最後のページを表示する必要がある場合はページ数をvisiblePagesに調整する
    const extraPages = visiblePages - pages.length
    if (extraPages > 0 && startPage > 1) {
      const pagesToAdd = Math.min(extraPages, startPage - 1)
      for (let i = startPage - pagesToAdd; i < startPage; i++) {
        pages.unshift(i)
      }
      pages.sort((a, b) => a - b)
    }
    if (extraPages > 0 && endPage < totalPage) {
      const pagesToAdd = Math.min(extraPages, totalPage - endPage)
      for (let i = endPage + 1; i <= endPage + pagesToAdd; i++) {
        pages.push(i)
      }
    }

    return pages
  }

  const createPaginationNumbers = () => {
    const paginationNumberList: ReactElement[] = []
    const pageNumbers = getPageNumbers(currentPage, totalPage)
    pageNumbers.forEach(pageNumber => {
      const primaryFlg = pageNumber === currentPage
      paginationNumberList.push(
        <PaginationNumber
          key={pageNumber}
          page={pageNumber}
          goPage={goPage}
          primaryFlg={primaryFlg}
        />,
      )
    })
    return paginationNumberList
  }

  return totalPage > 1 ? (
    <div className="flex justify-between items-center w-[913px] max-w-full mt-10 mx-auto">
      {createBackButton()}
      <ul className="flex gap-4 mx-2">{createPaginationNumbers()}</ul>
      {createNextButton()}
    </div>
  ) : (
    <></>
  )
}

interface PaginationNumberProps {
  page: number
  goPage: (page: number) => void
  primaryFlg: boolean
}

const PaginationNumber: FC<PaginationNumberProps> = ({
  page,
  goPage,
  primaryFlg,
}) => {
  return primaryFlg ? (
    <li className="px-2 py-1 rounded-md font-bold text-sm bg-green text-white cursor-default hover:opacity-100 md:px-3 md:py-2">
      {page}
    </li>
  ) : (
    <li
      className="px-2 py-1 rounded-md bg-gray-100 text-gray-400 font-bold text-sm cursor-pointer md:px-3 md:py-2"
      onClick={() => {
        goPage(page)
      }}
    >
      {page}
    </li>
  )
}
